<template>
    <div>
        <v-combobox
            v-model="newValue"
            :items="newOptions"
            :error="errors && errors.length > 0"
            outlined
            :search-input.sync="searchInput"
            @change="updateValue"
            :class="errors && errors.length ? 'hasError' : ''"
            item-text="text"
            :clearable="clearable || false"      
            :disabled="disabled || false"
            :label="required ? label + '*' : label" 
            hide-details
            :hide-no-data="!newOptions.length"
        >
            <template v-slot:append>
                <v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon>            
                <v-icon v-else-if="!newOptions.length"></v-icon>
            </template>
        </v-combobox>

        <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
    
</template>

<script>
import { Occupations } from '@/scripts/options'

export default {
    name: 'Combobox',
    data() {
        return {
            newValue: "",
            searchInput: null,
            newOptions: []
        }
    },
    props: {
        value: {
            type: [String, Array],
            default: null
        },
        type: String,
        rules: String,
        label: String,
        required: Boolean,
        disabled: Boolean,
        clearable: Boolean,
        name: String,
        options: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'change'
    ],
    methods: {
        emitValue() {  
            this.$emit('change', this.newValue)     
        },
        updateValue() {
            this.emitValue();
        }
    },
    computed: {
        optionsDisplay() {
            if (!this.newOptions.length) return [];
            else {
                if (this.newOptions[0].value) return this.newOptions;
                else {
                    return this.newOptions.map(o => {
                        return {value: o, text: o}
                    });
                }
            }
        },
    },
    mounted() {
        if (this.name == 'Occupation') {
            const occupations = Occupations;
            occupations.sort();
            this.newOptions = occupations;
        } else {
            this.newOptions = this.options;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.newValue = newVal;
            }
        },
    },
}
</script>

<style scoped>
    ::v-deep .v-text-field__details {padding: 5px 0!important;}
    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, 
    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
        min-height: auto;
    }
</style>
